'use client';

import { useSetRecoilState } from 'recoil';

import { DialogLocked } from '@/components/dialog-locked';
import { DialogSimple } from '@/components/dialog-simple';
import { DialogWelcome } from '@/components/dialog-welcome';
import { AlertDialog } from '@/components/ui/alert-dialog';
import { Notifications } from '@/components/ui/notifications';
import { useIdentifyUser } from '@/hooks/use-identify-user';
import { DRAWER_PORTAL_ROOT_ID } from '@/lib/constants';
import { alertDialogPortalState } from '@/state/alert';
import { dialogPortalState } from '@/state/user';

interface Props {
  children?: React.ReactNode[] | React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const setDialogPortal = useSetRecoilState(dialogPortalState);
  const setAlertDialogPortal = useSetRecoilState(alertDialogPortalState);

  // User tracking
  useIdentifyUser();

  return (
    <>
      {children}

      <DialogSimple />
      <DialogLocked />
      <DialogWelcome />
      <AlertDialog />
      {/* Container for the Dialog component */}
      <div ref={setDialogPortal} />
      {/* Container for the AlertDialog component */}
      <div ref={setAlertDialogPortal} />

      <div id={DRAWER_PORTAL_ROOT_ID} />

      <Notifications />
    </>
  );
};

export { Layout };
