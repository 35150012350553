export const FEATURE_FLAGS = {
  HIDE_QUOTES_BUTTON: 'hide-quotes-button',
  USER_NOTIFICATIONS: 'user-notifications',
  STANDALONE_SCHEDULE_SEARCH: 'standalone-schedule-search',
  BETA_SHIPMENT_TRACKING: 'betaShipmentTracking',
  TYPESENSE_AUTOCOMPLETE: 'typesense-autocomplete',
  SHIPMENT_TOPUP_PAYMENTS: 'shipment-topup-payments',
  EMISSIONS_CALCULATOR: 'emissions-calculator',
  SHIPMENT_META: 'shipment-meta',
  SHIPMENT_GROUPING: 'shipment-grouping',
} as const;
