export const joinByCommaAnd = (arr: string[], middleJoin = ', ', endJoin = ' and '): string =>
  arr.join(middleJoin).replace(/, ([^,]*)$/, `${endJoin}$1`);

export const formatStringToBracketSeparatedString = (str: string): string => {
  if (!str) return '';

  return str
    .split(',')
    .filter((x) => x)
    .map((x) => `[${x.trim()}]`)
    .join('');
};
