import { DISTANCE_UNITS } from '@/lib/constants';

/**
 * Transforms a raw distance to localised string with km
 * @param distance number in KM
 * @returns 1,000km
 */
export const formatDistance = (distance: number, unit?: string, longForm = false): string => {
  const formatter = new Intl.NumberFormat('en-EN');

  if (unit === DISTANCE_UNITS.MI) {
    const miles = Math.round(distance * 0.621371);
    return `${formatter.format(miles)} ${longForm ? 'Miles' : 'mi.'}`;
  }
  if (unit === DISTANCE_UNITS.NM) {
    const nm = Math.round(distance * 0.539957);
    return `${formatter.format(nm)} ${longForm ? 'Nautical Miles' : 'nm.'}`;
  }
  if (unit === DISTANCE_UNITS.TKM) {
    const km = Math.round(distance);
    return `${formatter.format(km)} t-km`;
  }
  if (unit === DISTANCE_UNITS.KM || !unit) {
    const km = Math.round(distance);
    return `${formatter.format(km)} ${longForm ? 'Kilometres' : 'km'}`;
  }

  return `${distance}`;
};
